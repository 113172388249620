import React, {
  useState, useEffect, useRef, useContext,
 } from 'react';
 import Storage from 'services/storage.service';
 import { toast } from 'react-toastify';
 import { ProfileContext } from 'contexts/ProfileContext.context';
 import { Button } from '@material-ui/core';
 import { ArrowForward } from '@material-ui/icons';
 import { NewUPPLinks } from '../../../constants/index';
 import { ReactComponent as StorefrontIcon } from '../../../assets/images/svg/vendor-admin.svg';
 import ProfileDropDown from './ProfileDropDown';
 import './TopBar.css';
 import QuestionDropDown from './QuestionDropDown';
 import SkeletanTopBarLoader from '../Loader/SkeletanTopBarLoader';
 import 'react-toastify/dist/ReactToastify.css';

 type TopBarProps = {
   leftComponentTitle?: string | React.ReactNode;
   rightComponent?: string | React.ReactNode;
   leftLink?: string | React.ReactNode;
   showSubMenu?: boolean;
 };

 export interface ITheme {
   logo?: string | null;
   leftSidebarBgColor?: string | null;
   leftSidebarActiveColor?: string | null;
   leftSidebarHoverColor?: string | null;
   leftSidebarTextColor?: string | null;
   topBarTextColor?: string | null;
   topBarBgColor?: string | null;
 }
 export interface IProfileDetail {
   firstName?: string | null;
   merchantName?: string | null;
   role?: string | null;
   grandparentName?: string | null;
   parentName?: string | null;
   theme?: ITheme | null;
 }

 const TopBar = ({
   leftLink,
   leftComponentTitle,
   rightComponent,
   showSubMenu = true,
 }: TopBarProps) => {
   const { profileDetail, setProfileDetail }: any = useContext(ProfileContext);
   const mountedRef = useRef(true);
   const currentDomain = window.location.origin;
   const isCustomeDomain = currentDomain !== process.env.REACT_APP_PUBLIC_URL;
   const isProd = process.env.REACT_APP_NODE_ENV === 'production';
   const [loading, setLoading] = useState(true);
   const isEnvironment = (env: string) => process.env.REACT_APP_NODE_ENV === env;
   const announcementEndDate = Date.UTC(2024, 7, 27, 18, 30, 0, 0);
   const announcementText = 'We are starting the migration at Optty to update our SBX environment. Please note that any updates made to this QA environment between 6:30 AM GMT and 6:30 PM GMT on July 26th will not be reflected beyond July 26th';
   useEffect(() => {
     if (isEnvironment('qa') && Date.now() < announcementEndDate) {
       announce(announcementText);
     }
     getProfileDetail();
     return () => {
       mountedRef.current = false;
     };
   }, [profileDetail]);

   const getProfileDetail = async () => {
     try {
       const data = profileDetail;
       if (Object.keys(data).length) {
         Storage.setItem('cachedProfileDetail', JSON.stringify(data));
         setProfileDetail(data);
         setLoading(false);
       }
     } catch (error) {
       console.log({ error });
     }
   };
   const announce = (text: string) => {
     toast.dark(`📢 ${text}`, {
       position: 'top-center',
       autoClose: false,
       hideProgressBar: true,
       closeOnClick: true,
       pauseOnHover: true,
       draggable: true,
       progress: undefined,
     });
   };

   let content: any = '';
   if (loading) {
     content = <SkeletanTopBarLoader />;
   } else if (!loading && Object.keys(profileDetail).length) {
     let tbClassName;
     const platformName = profileDetail?.theme?.platformName || profileDetail?.whitelabel?.serviceName || 'Universal Payments Platform';
     let title = isProd ? platformName : `Sandbox ${platformName}`;
     let mName = profileDetail.role === 'parent' ? `${profileDetail.parentName}` : `${profileDetail.merchantName}`;
     mName = profileDetail.role === 'grandparent' ? `${profileDetail.grandparentName} ` : mName;
     const merchantOrParentName = profileDetail.role === 'parent' ? `${title}` : ` ${title}`;
     title = profileDetail.role === 'grandparent' ? `${title}` : merchantOrParentName;
       if (profileDetail?.theme?.topBarBgColor) {
             document.documentElement.style.setProperty('--color-topbar-bg', profileDetail?.theme?.topBarBgColor);
             tbClassName = 'topbar-bg';
       } else {
         tbClassName = `${isProd ? 'prod-bg' : 'sandbox-bg'}`;
       }
       if (profileDetail?.theme?.topBarTextColor) document.documentElement.style.setProperty('--color-topbar-text', profileDetail?.theme?.topBarTextColor);

     content = (
       <div className={`top-header ${tbClassName}`}>
         <div className="left-box">
           <h3 className="title text-header">{mName}</h3>
           <h3 className="title text-header">{title}</h3>
         </div>
         <div className="right-box">
           { (!isCustomeDomain && (
           <Button
             className="new-oppty-btn glow-button"
           >
             <a
               className="new-oppty-link"
               href={`${NewUPPLinks[process.env.REACT_APP_NODE_ENV ?? 'local'] || NewUPPLinks.local}`}
             >Try New Optty
             </a>
             <ArrowForward className="new-oppty-arrow" />
           </Button>
           ))}
           <div className="role-box">
             <div className="profile-detail fs-13 fw-700">
               <StorefrontIcon className="store-icon" />
               <div className="text-capitalize">
                 <h3 className="profile-ellipsis">{mName}</h3>
                 <h3>({profileDetail.role})</h3>
               </div>
             </div>
           </div>
           <div className="question-box">
             <div className="profile-detail">
               <QuestionDropDown profileDetail={profileDetail} />
             </div>
           </div>
           <div className="profile-box">
             <div className="profile-detail">
               <ProfileDropDown firstName={profileDetail.firstName || ''} />
             </div>
           </div>
         </div>
       </div>
     );
   }

   return (
     <div className="top-div-container">
       {Object.keys(profileDetail).length && content}
       {Object.keys(profileDetail).length && showSubMenu && (
         <div className="top-descrp">
           {leftLink || (
             <div className="page-title text-white">{leftComponentTitle || 'Summary'}</div>
           )}
           {rightComponent || null}
         </div>
         )}
     </div>
   );
 };

 export default TopBar;
