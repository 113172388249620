import React, { useState } from 'react';

import './ChannelCheckoutConfigs.css';

export const ChannelsCheckoutConfigDropdown = ({ selectedBnpl, defaultCheckoutContent, setEditorHTML }: any) => {
  const [selectedChannel, setSelectedChannel] = useState('selectChannel');

  const onChannelChange = (e: any) => {
    setSelectedChannel(e.target.value);
    if (e.target.value === 'selectChannel') {
      setEditorHTML(selectedBnpl.checkoutConfig.content);
    } else if (e.target.value === 'default') {
      setEditorHTML(defaultCheckoutContent || selectedBnpl.checkoutConfig.content);
    } else {
      const [config] = selectedBnpl.channelsCheckoutConfig.filter((item: any) => item.channelId === e.target.value);
      setEditorHTML(config.checkoutConfig.content);
    }
  };
  return (
    <div className="channel-select-dropdown">
      <strong>Select Channel</strong>
      <select
        className="channel-select"
        name="select-channel"
        value={selectedChannel}
        onChange={onChannelChange}
        placeholder="Select Channel"
      >
        <option value="selectChannel">Select Channel</option>
        <option value="default">Default</option>
        {
          selectedBnpl?.channelsCheckoutConfig.map((cc: any) => {
            return <option value={cc.channelId}>{cc.name}</option>;
          })
        }
      </select>
    </div>
  );
};