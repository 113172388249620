import {
  API_KEY, API_SECRET, IProviderInputInterface, SAME_VALUE,
} from './payment-provider-types';

export const voltConfigs: IProviderInputInterface[] = [
  {
    name: 'username',
    label: 'Username',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'Log in to Volt Fusebox, Navigate to Dashboard > Configuration > Customer tab and generate your Username under Credentials',
  },
  {
    name: 'password',
    label: 'Password',
    type: 'password',
    keyType: API_SECRET,
    isRequired: true,
    infoGuide: 'Log in to Volt Fusebox, Navigate to Dashboard > Configuration > Customer tab and generate your Password under Credentials',
  },
  {
    name: 'publicKeyId',
    label: 'Public Key ID',
    type: 'text',
    keyType: API_KEY,
    isRequired: false,
    infoGuide: 'Log in to Volt Fusebox, Navigate to Configuration > Customers > Payment Configuration > Signature Keys and generate a Public key ID',
  },
  {
    name: 'privateKey',
    label: 'Private Key',
    type: 'password',
    keyType: API_SECRET,
    isRequired: false,
    infoGuide: 'Log in to Volt Fusebox, Go to Configuration > Customers > Payment Configuration > Signature Keys and generate a Private key pair',
  },
];

export const visaCardConfigs = [
  {
    name: 'merchantId',
    label: 'Merchant ID',
    type: 'text',
    keyType: API_KEY,
  },
  {
    name: 'apiKey',
    label: 'Key',
    type: 'text',
    keyType: API_KEY,
  },
  {
    name: 'sharedSecret',
    label: 'Shared Secret',
    type: 'password',
    keyType: API_SECRET,
  },
];

export const tabbyConfigs: IProviderInputInterface[] = [
  {
    name: 'apiKey',
    label: 'Api Key',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'To obtain your Api Key, contact your Tabby account manager or access them directly from the Dashboard, depending on your integration type.',
  },
  {
    name: 'merchantCode',
    label: 'Merchant Code',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'Merchant Code represents a merchant country or a specific store within the country',
  },
  {
    name: 'apiSecret',
    label: 'Api Secret',
    type: 'password',
    keyType: API_SECRET,
    isRequired: true,
    infoGuide: 'To obtain your Api secret, contact your Tabby account manager or access them directly from the Dashboard, depending on your integration type.',
  },
];

export const grabConfigs: IProviderInputInterface[] = [
  {
    name: 'partnerId',
    label: 'Partner ID',
    type: 'text',
    keyType: API_KEY,
    infoGuide: 'Please enter partner ID',
  },
  {
    name: 'clientId',
    label: 'Client ID',
    type: 'text',
    keyType: API_KEY,
    infoGuide: 'Please enter client ID',
  },
  {
    name: 'merchantId',
    label: 'Merchant ID',
    type: 'text',
    keyType: API_KEY,
    infoGuide: 'Please enter merchant ID',
  },
  {
    name: 'partnerSecret',
    label: 'Partner Secret',
    type: 'password',
    keyType: API_SECRET,
    infoGuide: 'Please enter partner secret',
  },
  {
    name: 'clientSecret',
    label: 'Client Secret',
    type: 'password',
    keyType: API_SECRET,
    infoGuide: 'Please enter client secret',
  },
];

export const zipConfig: IProviderInputInterface[] = [
  {
    name: 'Api-Key',
    label: 'API Key',
    type: 'password',
    keyType: SAME_VALUE,
    infoGuide: 'Please Enter credentials provided by provider',
  },
];

export const fiservCardsAuConfig: IProviderInputInterface[] = [
  {
    name: 'apiKey',
    label: 'API Key',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'Log in to the Fiserv Portal and navigate to \'API Key\' to copy/create your API key',
  },
  {
    name: 'storeId',
    label: 'Store ID',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'To obtain the Store ID, please contact the Fiserv Support Team',
  },
  {
    name: 'sharedSecret',
    label: 'Shared Secret',
    type: 'password',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'To obtain the Shared Secret, please contact the Fiserv Support Team',
  },
  {
    name: 'apiSecret',
    label: 'API Secret',
    type: 'password',
    keyType: API_SECRET,
    isRequired: true,
    infoGuide: 'Log in to the Fiserv Portal and navigate to \'Secret Key\' to copy/create your Secret key.',
  },
];

export const tamaraConfig: IProviderInputInterface[] = [
  {
    name: 'apiKey',
    label: 'Api Key',
    type: 'password',
    keyType: SAME_VALUE,
    isRequired: true,
    infoGuide: 'Please enter Api Key',
  },
];

export const crediteaConfig: IProviderInputInterface[] = [
  {
    name: 'apiKey',
    label: 'Api Key',
    type: 'password',
    keyType: SAME_VALUE,
    isRequired: true,
    infoGuide: 'Please enter Api Key',
  },
];

export const hummConfigs: IProviderInputInterface[] = [
  {
    name: 'apiKey',
    label: 'Api Key',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'Please enter Api Key',
  },
  {
    name: 'storeId',
    label: 'Store ID',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'Please enter valid Store ID',
  },
  {
    name: 'apiSecret',
    label: 'Api Secret',
    type: 'password',
    keyType: API_SECRET,
    isRequired: true,
    infoGuide: 'Please enter Api Secret',
  },
  {
    name: 'refreshToken',
    label: 'Refresh Token',
    type: 'text',
    keyType: API_SECRET,
    isRequired: true,
    infoGuide: 'Please enter Refresh Token',
  },
];

export const contactEgConfig: IProviderInputInterface[] = [
  {
    name: 'api_key',
    label: 'Api Key',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'Log in to Paymob portal, Navigate to Settings > Account info to View / Recreate your API key',
  },
  {
    name: 'publicKey',
    label: 'Public Key',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'Log in to Paymob portal, Navigate to Settings > Account info to View / Recreate your Public Key',
  },
  {
    name: 'integrationId',
    label: 'Integration Id',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'Log in to Paymob portal, Navigate to Developers > Payment integrations to obtain your Integration Id',
  },
  {
    name: 'apiSecret',
    label: 'Secret Key',
    type: 'password',
    keyType: API_SECRET,
    isRequired: true,
    infoGuide: 'Log in to Paymob portal, Navigate to Settings > Account info to View / Recreate your Secret Key',
  },
];

export const nuapayConfigs: IProviderInputInterface[] = [
  {
    name: 'orgId',
    label: 'Organisation Id',
    type: 'text',
    keyType: API_SECRET,
    isRequired: true,
    infoGuide: 'To obtain your Organisation Id, contact your Nuapay account manager or access them directly from the Dashboard.',
  },
  {
    name: 'apiKey',
    label: 'API Key',
    type: 'password',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'To obtain your Api Key, contact your Nuapay account manager or access them directly from the Dashboard.',
  },
];
