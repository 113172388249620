import React, { Component, ElementType } from 'react';
import { createBrowserHistory } from 'history';
import OpttyScreenLoader from '../../components/layouts/Loader/OpttyScreenLoader';

type ImportComponent = () => Promise<{ default: ElementType }>;

export interface IAsyncComponentState {
  component: ElementType | null;
}

const asyncComponent = (importComponent: ImportComponent) => {
  class AsyncComponent extends Component<{}, IAsyncComponentState> {
    state: IAsyncComponentState = {
      component: null,
    };

    async componentDidMount() {
      const { default: component } = await importComponent();

      this.setState({
        component,
      });
    }

    render() {
      const { component: Comp } = this.state;

      if (!Comp) return <OpttyScreenLoader isLoading />;

      return <Comp {...this.props} />;
    }
  }

  return AsyncComponent;
};

export { asyncComponent };

export const NewUPPLinks: any = {
  local: `http://localhost:${process.env.PORT}/app`,
  development: 'https://upp.dev.optty.com/',
  staging: 'https://upp.staging.optty.com/',
  qa: 'https://upp.qa.optty.com/',
  production: 'https://upp.optty.com/',
  sandbox: 'https://upp.qa.optty.com/',
  sre: 'https://upp.sre.optty.com/',
};

const history = createBrowserHistory();

export default history;
